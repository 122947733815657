<template>
  <div>
    <v-form
      class="d-flex align-center"
      ref="form"
      v-model="formValid"
      @submit.prevent="getEdisList(false)"
    >
      <v-row class="ma-0 pa-4 align-center">
        <v-col cols="12" sm="12" md="5" lg="3" class="px-2 py-0">
          <div class="pb-1">Date</div>
          <v-menu
            v-model="dateMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                @click:append="dateMenu = true"
                clearable
                class="apiKeyInput"
                v-model="computedDateFormatted"
                outlined
                persistent-hint
                :menu-props="{ bottom: true, offsetY: true }"
                :rules="fromDateRules"
                placeholder="Select the From Date"
                append-icon="mdi-calendar"
                readonly
                dense
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              :max="new Date().toISOString().substr(0, 10)"
              v-model="date"
              :show-current="true"
              no-title
              @input="dateMenu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="12" md="5" lg="7">
          <div class="px-5">
            <v-btn
              height="40"
              type="submit"
              depressed
              class="text-capitalize fsize12 mx-4"
              color="primary"
              >Submit
            </v-btn>
          </div>
        </v-col>
        <v-col></v-col>
      </v-row>
      <div class="d-flex pa-4" v-if="edisList.length > 0">
        <v-btn
          height="32"
          min-width="fit-content"
          color="primary"
          class="text-capitalize mr-4 outlined"
          @click="createAndDownload()"
        >
          DB Format
        </v-btn>

        <v-btn
          height="32"
          min-width="fit-content"
          color="primary"
          class="text-capitalize mr-4 outlined"
          @click="downloadTable(edisList)"
        >
          Download <v-icon class="mx-2 fsize18">mdi-cloud-download </v-icon>
        </v-btn>
      </div>
    </v-form>
    <v-data-table
      id="tableData"
      v-if="edisList.length > 0"
      :headers="titlename"
      :items="edisList"
      :items-per-page="10"
      class="elevation-1"
    ></v-data-table>
    <div v-else>
      <div class="text-center pa-5">
        <v-icon class="display-2 pb-4">mdi-thought-bubble-outline</v-icon>
        <div class="">Data Not Found</div>
      </div>
    </div>
    <v-dialog
      class="json-dialog"
      v-model="dialog"
      max-width="600"
      style="overflow-x: hidden"
    >
      <v-card class="">
        <div
          class="d-flex dialog-header"
          style="
            justify-content: space-between;
            border-bottom: -16px solid black;
          "
        >
          <div class="font-weight-bold fsize14 mx-6">JSON</div>
          <div class="">
            <v-btn
              height="28"
              depressed
              outlined
              text
              class="text-capitalize fsize12"
              @click="formatFlag ? format() : ''"
            >
              Format</v-btn
            >
            <v-btn
              height="28"
              depressed
              outlined
              text
              :color="copyText == 'Copy' ? 'black' : 'green'"
              class="text-capitalize fsize12 mx-4"
              @click="copyTextFunc()"
              >{{ copyText }}</v-btn
            >
            <v-icon @click="dialog = false" color="primary"
              >mdi-close-circle</v-icon
            >
          </div>
        </div>
        <div
          v-if="formatFlag"
          class="fsize14 line-height-2 pa-4"
          id="jsonData"
          ref="jsonData"
        >
          {{ currentJson }}
        </div>
        <div v-else>
          <json-viewer :value="currentJson" sort></json-viewer>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import JsonViewer from "vue-json-viewer";
Vue.use(JsonViewer);
import { mapGetters } from "vuex";
import commonFunc from "../../mixins/commonFunctions";
export default {
  name: "EdisList",
  mixins: [commonFunc],

  data: () => ({
    dialog: false,
    currentJson: {},
    formatFlag: true,
    copyText: "Copy",
    //
    date: new Date().toISOString().substr(0, 10),
    dateMenu: false,
    fromDateRules: [(v) => !!v || "From Date is required"],
    formValid: true,

    titlename: [
      { text: "Bold", align: "start", sortable: false, value: "boId" },
      { text: "ClientId", sortable: false, value: "userId" },
      { text: "ReqId", sortable: false, value: "reqId" },
      { text: "ReqType", sortable: false, value: "reqType" },
      { text: "ResId", sortable: false, value: "resId" },
      { text: "ResStatus", sortable: false, value: "resStatus" },
      { text: "ResTime", sortable: false, value: "resTime" },
      { text: "ResError", sortable: false, value: "resError" },
      { text: "Remarks", sortable: false, value: "remarks" },
      { text: "TxnReqId", sortable: false, value: "txnReqId" },
      { text: "TxnId", sortable: false, value: "txnId" },
      { text: "Status", sortable: false, value: "edisStatus" },
      { text: "ErrorCode", sortable: false, value: "errorCode" },
      { text: "Quantity", sortable: false, value: "authQty" },
      { text: "Isin", sortable: false, value: "isin" },
      {
        text: "PlacedAt",
        sortable: false,
        value: "placedAt".slice(0, 10),
        width: "110px",
      },
      { text: "SettlementNo", sortable: false, value: "settelId" },
      { text: "ExecDate", sortable: false, value: "execDate" },
    ],

    JsonFields: [
      "Bold",
      "ClientId",
      "ReqId",
      "ReqType",
      "ResId",
      "ResStatus",
      "ResTime",
      "ResError",
      "Remarks",
      "TxnReqId",
      "TxnId",
      "Status",
      "ErrorCode",
      "Quantity",
      "Isin",
      "PlacedAt",
      "SettlementNo",
      "ExecDate",
    ],
  }),

  computed: {
    ...mapGetters({
      loading: "getLoader",
      search: "getSearch",
    }),
    ...mapGetters("reports", {
      edisList: "getEdisList",
      edisDownloadData: "getEdisDownloadData",
    }),
    // ...mapGetters("reports", {
    //   edisRecordList: "getData",
    //   dbdownloadLink: "getDbDownloadLink",
    // }),
    computedDateFormatted: {
      get() {
        return this.VutifyFormatDate(this.date,'/');
      },
      set() {},
    },
  },

  watch: {
    dialog(val) {
      this.formatFlag = true;
    },
  },

  created() {
    this.getEdisList(true);
  },

  methods: {
    async getEdisList(val) {
      if (val || this.$refs.form.validate()) {
        let jsonObj = {
          date: this.date,
        };
        await this.$store.dispatch("reports/getEdisList", jsonObj);
      }
      this.downLoadDbFormat();
    },

    callDialog(data) {
      data && data != `""` ? (this.dialog = true) : "";
      this.currentJson = data;
    },

    format() {
      this.formatFlag ? (this.currentJson = JSON.parse(this.currentJson)) : "";
      this.formatFlag = !this.formatFlag;
    },

    copyTextFunc() {
      navigator.clipboard.writeText(JSON.stringify(this.currentJson));
      this.copyText = "Copied";
      setTimeout(() => {
        this.copyText = "Copy";
      }, 2500);
    },

    downLoadDbFormat() {
      this.$store.dispatch("reports/getEdisData", this.date);
    },

    createAndDownload(download = "edis.txt", type = "text/plain") {
      let content = this.edisDownloadData;
      const el = (sel, par) => (par || document).querySelector(sel);
      const elNew = (tag, prop) =>
        Object.assign(document.createElement(tag), prop);

      const file = new Blob([content], { type });
      const href = URL.createObjectURL(file);
      const elAnchor = elNew("a", { href, download });
      el("body").append(elAnchor);
      elAnchor.click();
      elAnchor.remove();
      URL.revokeObjectURL(href);
    },

    downloadTable(data) {
      let arr = JSON.parse(JSON.stringify(data));
      arr.forEach((el) => {
        delete el.validDays;
      });
      this.downloadUnicodeCSV(arr);
    },
  },
};
</script>
